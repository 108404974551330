import React from "react"
import PropTypes from "prop-types"

import Layout from "../../layouts/main"

import SEO from "../../components/seo/seo"
import BladeDecoration from "../../components/blade-decoration/blade-decoration"
import TimelineTout from "../../components/timeline-tout/timeline-tout"
import StoreTout from "../../components/store-tout/store-tout"
import PlayTout from "../../components/play-tout/play-tout"
import NewsTout from "../../components/news-tout/news-tout"
import CharactersTout from "../../components/characters-tout/characters-tout"

import HomeNews from "./home-news"
import HomeVideo from "./home-video"
import HomeShop from "./home-shop"
import HomeHeader from "./home-header"
import HomeSwitchCta from "./home-switch-cta"

import { classnames } from "../../utils/helpers"

import useInView from "../../hooks/use-in-view"
import useLocale from "../../hooks/use-locale"

import styles from "./home.module.scss"

const HomeTemplate = function({ locale }) {
  const { globalLocale } = useLocale()
  const [toutsRef, toutsInView] = useInView()

  return (
    <Layout pageName="home" className={styles.Home}>
      <SEO title={locale.meta.title} description={locale.meta.description} />

      <header>
        <HomeHeader locale={locale.header} />
      </header>

      <main>
        <div className={styles.OverflowContainer}>
          <HomeSwitchCta locale={locale.switchCta} />

          <HomeNews locale={locale.newsBlade} />

          <HomeVideo className={styles.Video} locale={locale.videoBlade} />

          <HomeShop locale={locale.shopBlade} />

          <div
            className={classnames([styles.Touts, toutsInView && "vp-animate"])}
            ref={toutsRef}
          >
            <BladeDecoration type="small-wave" />

            <div className={classnames([styles.Touts__content, "row"])}>
              <div className="column column--6 txt-center vp-slide vp-slide--down">
                <TimelineTout trackPrefix="home" />
              </div>

              <div className="column column--6 txt-center vp-slide vp-slide--down vp-delay-1">
                <CharactersTout trackPrefix="home" />
              </div>
            </div>

            <div className={classnames([styles.Touts__content, "row"])}>
              <div className="column column--6 txt-center vp-slide vp-slide--down">
                <PlayTout trackPrefix="home" />
              </div>
              <div className="column column--6 txt-center vp-slide vp-slide--down vp-delay-1">
                {globalLocale.storeTout ? (
                  <StoreTout trackPrefix="home" />
                ) : (
                  <NewsTout trackPrefix="home" />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

HomeTemplate.propTypes = {
  locale: PropTypes.object,
}

export default HomeTemplate
